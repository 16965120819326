
































































































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { metadata } from "@/plugins/metadata";
import { types } from "@/services/westore/enums";

export default mixins(MyMixin).extend({
  name: "Clients",
  mounted() {
    this.trackMyPageView();
  },
  metaInfo: {
    title: metadata.Clients.title,
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      { name: "robots", content: "follow, index" },
      {
        name: "description",
        content: metadata.Clients.description,
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, user-scalable=no",
      },
    ],
  },
  computed: {
    types() {
      return types;
    },
  },
  data: () => ({
    Clients: [
      {
        image: require("@/assets/new-site/clients/client1.png"),
      },
      {
        image: require("@/assets/new-site/clients/client2.png"),
      },
      {
        image: require("@/assets/new-site/clients/client3.png"),
      },
      {
        image: require("@/assets/new-site/clients/client4.png"),
      },
      {
        image: require("@/assets/new-site/clients/client5.png"),
      },
      {
        image: require("@/assets/new-site/clients/client6.png"),
      },
      {
        image: require("@/assets/new-site/clients/client7.png"),
      },
      {
        image: require("@/assets/new-site/clients/client8.png"),
      },
      {
        image: require("@/assets/new-site/clients/client9.png"),
      },
      {
        image: require("@/assets/new-site/clients/client10.jpg"),
      },
      {
        image: require("@/assets/new-site/clients/client11.png"),
      },
      {
        image: require("@/assets/new-site/clients/client12.jpeg"),
      },
    ],
  }),
});
