import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"technology"}},[_c('ws-app-bar',{attrs:{"dark":""}}),_c(VSheet,{staticClass:"d-flex flex-column justify-space-between",attrs:{"color":"ws-black","dark":"","elevation":"0"}},[_c(VImg,{staticClass:"flex-grow section-md",attrs:{"eager":"","aspect-ratio":"4","position":_vm.$vuetify.breakpoint.mdAndUp ? '100% 55%' : 'center center',"src":require('../assets/new-site/clients/hero.jpg'),"xgradient":"to right, rgba(12 ,25 ,34, .99),  rgba(12 ,25 ,34, .0)","gradient":_vm.$vuetify.breakpoint.mdAndUp
          ? 'to right, rgba(12 ,25 ,34, .99),  rgba(12 ,25 ,34, .0)'
          : 'to right, rgba(12 ,25 ,34, .7),  rgba(12 ,25 ,34, .7)'}},[_c(VContainer,{staticClass:"py-14"},[_c(VRow,{attrs:{"wrap":""}},[_c(VCol,{attrs:{"cols":"12","md":"6","xlg":"5"}},[_c('h1',{staticClass:"mb-4 hidden-sm-and-down"},[_c('ws-gradient-text',{staticClass:"text-uppercase"},[_vm._v(" Introducing some of our highly valued clients ")])],1),_c('h1',{staticClass:"mb-6 hidden-md-and-up"},[_vm._v(" Introducing some of our highly valued clients ")]),_c('p',{staticClass:"body-1 my-6 hidden-sm-and-down"},[_vm._v(" who we’ve had the pleasure of working with. It’s been a privilege to have been able to play a part in the success of businesses of all sizes across various industries. ")]),_c('div',[_c(VBtn,{staticClass:"mr-3",attrs:{"color":"white","light":""},on:{"click":function($event){_vm.logGAEvent('Clients', 'Get a quote', "Hero Banner");
                  _vm.$vuetify.goTo('#list-your-warehouse');}}},[_vm._v(" Get a Quote ")])],1)])],1)],1)],1)],1),_c('section',{staticClass:"clients"},[_c(VContainer,[_c(VRow,[_c('h2',[_vm._v("Our Clients")]),_vm._l((_vm.Clients),function(item,i){return _c(VCol,{key:i,staticClass:"logos-wrapper",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('img',{staticClass:"logo",attrs:{"src":item.image,"alt":"clients"}})])})],2)],1)],1),_c('section',{staticClass:"white--text ws-banner ws-banner-pink section-lg",attrs:{"id":"list-your-warehouse"}},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('h2',{staticClass:"mb-6"},[_vm._v("We Store.You Grow.")]),_c('h3',{staticClass:"mb-5"},[_vm._v(" Solving the hassles of your storage and delivery. ")]),_c(VList,{staticClass:"transparent",attrs:{"dense":"","dark":""}},_vm._l(([
                'Optimise unused space',
                'Introduce new revenue streams',
                'No long-term commitments' ]),function(p){return _c(VListItem,{key:p},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-check-circle-outline")])],1),_c(VListItemContent,[_c('span',{staticClass:"body-1 font-weight-bold gray-text"},[_vm._v(_vm._s(p))])])],1)}),1)],1),_c(VCol,{attrs:{"cols":"12","md":"6","lg":"4","offset-lg":"1"}},[_c('ws-common-form',{attrs:{"title":"Get a Call","title-color":"primary","color":"white","light":"","comments-label":"Comments / Questions","submit-color":"ws-green","form-type":_vm.types.VendorInterest}})],1)],1)],1)],1),_c('ws-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }